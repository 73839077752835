<template>
  <div class="deposit-styles">
    <vca-row>
      <div>
        <h3>{{ $t("deposit.external.organisation.label") }}</h3>
        <span>{{ value.organisation }}</span
        ><br /><br />
        <h3>{{ $t("deposit.external.asp.label") }}</h3>
        <span>{{ value.asp }}</span
        ><br /><br />
        <h3>{{ $t("deposit.external.email.label") }}</h3>
        <span>{{ value.email }}</span
        ><br /><br />
      </div>
      <div>
        <h3>{{ $t("deposit.external.address.label") }}</h3>
        <span>{{ value.address }}</span
        ><br /><br />
        <h3>{{ $t("deposit.external.purpose.label") }}</h3>
        <span>{{ value.purpose }}</span>
      </div>
    </vca-row>

    <DepositExternalInfoBox v-model="reason_for_payment" />
  </div>
</template>
<script>
import DepositExternalInfoBox from "./info/DepositExternalInfoBox.vue";
export default {
  name: "DepositExternalInfo",
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          organisation: "",
          asp: "",
          email: "",
          address: "",
          reciept: false,
          purpose: "",
        };
      },
    },
    reason_for_payment: {
      type: String,
      default: "",
    },
  },
  components: { DepositExternalInfoBox },
};
</script>

<template>
  <vca-card v-if="finish">
    <h2>{{ $t("deposit.edit.header") }}</h2>
    <DepositEdit />
  </vca-card>
  <Spinner v-else />
</template>
<script>
import DepositEdit from "@/components/finance/deposits/DepositEdit.vue";
import { mapGetters } from "vuex";
import Spinner from "@/components/utils/Spinner.vue";
export default {
  name: "DepositEditView",
  components: {
    Spinner,
    DepositEdit,
  },
  data() {
    return {
      finish: false,
    };
  },
  created() {
    this.create().then(() => (this.finish = true));
    this.$store.commit("takings/pg/route", "finances/taking");
    this.$store.commit("takings/pg/context", "takings/pg");
    this.$store.dispatch({
      type: "takings/pg/request",
      data: { initial: true },
    });
  },
  methods: {
    async create() {
      await this.$store.dispatch({
        type: "deposits/getByID",
        data: this.$route.params.id,
      });
      await this.$store.dispatch({ type: "crews/public" });
    },
  },
  computed: {
    ...mapGetters({
      edit: "deposits/edit",
    }),
  },
};
</script>
